body a {
  text-decoration: none;
}
.audio-player {
  display: flex;
  flex-direction: row;
  cursor: default;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  flex: 1;
  align-items: center;
}
.audio-player ::-webkit-scrollbar {
  display: none;
}
.audio-player-track-name {
  overflow-x: hidden;
  overflow-y: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  white-space: nowrap;
  width: 32%;
  /* margin: 0em 0.15em; */
}
.audio-player-seeker {
  display: flex;
  width: 35%;
}
.audio-player-controls,
.audio-player-time {
  display: flex;
  width: fit-content;
}
/* .audio-player-time,
.audio-player-seeker,
.audio-player-volume {
  margin: 0em 0.15em;
} */
.audio-player-volume {
  display: flex;
  width: 33%;
}
.slide-container {
  display: flex;
  align-items: center;
  /* margin-right: 12px; */
  /* margin-left: 12px; */
  width: 100%;
}
#volume-button {
  display: flex;
}
.marquee {
  width: auto;
  min-width: max-content;
  /* margin-right: 0.3em; */
}
.volume-iconStyle {
  padding: 12px;
}
.current-time,
.duration,
#play,
#pause,
#volume,
#forward,
#loop,
#rewind {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
img {
  width: auto;
  /* margin: 0em 0.15em; */
}
